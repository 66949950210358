// Full screen modal ("fs")
$fs-modal-header: 62px
$fs-modal-margin: 25px
$fs-modal-margin-total: $fs-modal-margin * 2
$fs-modal-body-padding: 1rem
$fs-modal-body-padding-total: $fs-modal-body-padding * 2

@mixin full-screen-height-modal
  margin: $fs-modal-margin
  height: calc(100vh - #{$fs-modal-margin-total})
  display: flex
  justify-content: center
  max-width: none
  .modal-content
    height: inherit
    max-width: calc(1140px + 60px)
    width: 100%
  .modal-body
    padding: $fs-modal-body-padding
  iframe
    border: 0
    width: 100%
    height: 100%

@mixin full-screen-width-modal
  margin: $fs-modal-margin
  width: calc(100vw - #{$fs-modal-margin-total})
  display: flex
  justify-content: center
  max-width: none
  .modal-content
    max-width: none
    width: inherit
  .modal-body
    padding: $fs-modal-body-padding

@mixin full-screen-modal
  @include full-screen-height-modal
  @include full-screen-width-modal

.modal
  .modal-dialog
    &.full-height
      @include full-screen-height-modal
    &.full-width
      @include full-screen-width-modal
    &.full-screen
      @include full-screen-modal
  .modal-header
    flex-shrink: 0
    border-bottom: 0
    + .modal-body
      padding-top: 0
  .modal-title
    font-size: 1.25rem
    overflow: hidden
    text-overflow: ellipsis
    // white-space: nowrap
    padding-right: 20px
  .modal-content
    max-height: calc(100vh - #{$fs-modal-margin-total})
    overflow: hidden
  .modal-body
    overflow-y: auto
    > .container
      height: 100%
    .close
      position: absolute
      right: -15px
      top: -15px
  .modal-footer
    border-top: 0
    button + button
      margin-left: 15px

.modal-image
  @include full-screen-modal
  .modal-content
    width: auto
    max-height: inherit
  .modal-body
    overflow: hidden
  img
    display: block
    margin: 0 auto
    max-width: 100%
    height: auto
    max-height: calc(100vh - #{$fs-modal-body-padding-total} - #{$fs-modal-margin-total})

.with-theme
  .close
    color: #ffffff
    opacity: 1
    text-shadow: none
    font-family: initial
    font-size: 24px
    font-weight: normal
    padding: 0 17px
    line-height: 48px
    height: 48px
    margin-bottom: 0
    margin-top: 0
    &:focus
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75)
      outline: 0
  .modal-header
    height: $fs-modal-header
    line-height: $fs-modal-header
    padding: 0 17px 0 24px
    border: 0
  .modal-title
    font-family: 'Roboto'
    font-size: 1.25rem
    font-weight: 500
    color: #ffffff
    line-height: $fs-modal-header
  .modal-body
    padding: 1rem 1.5rem !important
    .container
      padding: 0

.lx-close-btn
  background: transparent
  border: 0
  box-shadow: none
  position: absolute
  padding: 0
  right: 1px
  top: 3px
  opacity: .8
  &:hover
    background: transparent
    opacity: 1
  &:focus, &:active, &:focus:active
    opacity: 1
    background: transparent !important
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .75) !important