.assignment-edit-btn
  display: flex
  align-items: flex-end
  margin-top: 20px
  flex-wrap: wrap

  @media (min-width: 680px)
    margin-top: 0px

  .label
    color: #2F2F39
    font-family: 'Roboto'
    font-weight: 400
    font-size: 18px
    line-height: 36px
    margin-bottom: 24px
    margin-right: 20px

  &__buttons
    margin-bottom: 24px

  button
    white-space: nowrap
    + button
      margin-left: 20px
