.page-header-with-selector
  display: flex
  align-items: center

  select
    height: 40px
    margin-left: 16px

  .info-icon
    fill: white
    height: 24px
    width: 24px
    margin-left: 16px
