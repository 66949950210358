// Printing styles for Learn

$mainGray: #666
$mainBlack: #000

@media print
  // Body
  body 
    margin: 2rem
  // Navbar
  .navbar
    display: none !important
  // Main
  main
    padding-top: 0
    &.overview
      background: none
  .course-mast
    background: none !important
    &__title
      color: $mainBlack
  .mast__waves,
  .mast__button
    display: none !important
  .breadcrumb-divider svg path
    fill: $mainGray
  .breadcrumb-item
    color: $mainGray
    &__link
      color: $mainGray

  // TOC
  .course-dashboard--course,
  .course-dashboard--unit,
  .course-dashboard--module
    .toc__list-item--unit
      border-bottom: 1px solid $mainGray
      border-radius: 0
      padding-bottom: 1rem
      .toc__toggler
        display: none !important
      .toc__link
        padding-left: 0
      .collapse:not(.show)
        display: block
    .toc__list-item--module
      border-top: 0

  // Footer elements
  .current-timezone,
  .instructor-toolbar
    display: none !important
  .lx-footer, 
  main.overview + .lx-footer
    background: none !important
    display: none
  .lx-footer
    color: $mainGray
    a
      color: $mainGray

  .lo-accordion__toggler svg
    display: none
  .lo-accordion__content.collapse
    display: block

  .top-pagination
    display: none

  .lx-footer_navigation 
    display: none

  .page-location-bar
    background-color: #fff
    border-bottom-color: $mainBlack
  
  .workbook-page-container.container
    width: 100%
    max-width: 100%
    min-width: 100% !important
    padding-right: 2rem !important
    padding-left: 2rem !important
    h1.sr-only
      display: block !important
      position: relative
      width: auto
      height: auto
      margin-bottom: 60px

  .progress-bar
    background-color: $mainBlack !important
  .page-location-bar_progress
    border-color: $mainBlack
    background-color: #fff
  .iframe-placeholder,
  .workbook-page-iframe
    break-inside: avoid
  .iframe-placeholder
    padding-bottom: 1300px