.assessment-settings-dialog
  &.modal
    .modal-dialog
      @media (min-width: 576px)
        max-width: 400px
    .modal-header
      border: 0
      padding: 0
    .modal-title
      font-size: 20px
      line-height: 30px
      padding: 24px 24px 16px 24px
      white-space: initial
    .modal-body
      border: 0
      font-size: 16px
      line-height: 24px
      padding: 0px 24px
    .modal-footer
      border: 0
      padding: 24px
      button + button
        margin-left: 5px
      .spinner
        margin-left: 8px