.tos
  background-color: #fafafa
  &__container
    margin: 0 auto
    padding: 60px 15px 0 15px
    @include media-breakpoint-up(md)
      width: 530px
      padding: 60px 0 0 0
  &__main-title
    font-size: 34px
  &__document
    width: 100%
    height: 300px
    border: 1px solid #999
    overflow: hidden scroll
    margin-bottom: 25px
    padding: 15px
    color: #1b1b26
    font-size: 14px
    h1
      font-size: 34px
      margin: 20px 0 10px
      &:first-of-type
        margin-top: 0
    h2
      font-size: 30px
    h3
      font-size: 28px
  &__form
    .custom-checkbox
      border: 1px solid #cccccc
      border-radius: 3px
      padding: 15px 15px 15px 39px
      margin-bottom: 10px
  &__button
    &:disabled
      cursor: not-allowed
