// Alert mixin
@mixin alert-variation($bg-color, $dark-color)
  background-color: $bg-color
  border-color: $dark-color
  color: $dark-color
  a
    border-bottom: 1px solid $dark-color

// Primary alert
.primary-alert
  @include alert-variation($ocean1, $ocean6)
