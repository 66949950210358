// Mixins
=custom-scrollbar($trackColor, $bgColor)
  scrollbar-track-color: $bgColor
  scrollbar-3dlight-color: $bgColor
  scrollbar-highlight-color: $bgColor
  scrollbar-track-color: $trackColor
  scrollbar-arrow-color: $trackColor
  scrollbar-shadow-color: $trackColor
  scrollbar-dark-shadow-color: $bgColor

  &::-webkit-scrollbar
    width: 8px
    height: 1.125rem

  &::-webkit-scrollbar-track
    background: $bgColor
    -webkit-box-shadow: inset 0 0 0 0

  &::-webkit-scrollbar-thumb
    border-radius: 4px
    background: $trackColor

@mixin flex-vertically-align-center
  display: flex
  align-items: center
  justify-content: center
