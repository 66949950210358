$watch-color: $cloud5

.watch
  border: 2px solid $watch-color
  border-radius: 10px
  width: 13px
  height: 13px
  display: inline-block
  position: relative
  &::before
    display: inline-block
    content: ''
    width: 4px
    height: 2px
    background: $watch-color
    position: absolute
    top: 3px
    left: 4px
  &::after
    display: inline-block
    content: ''
    position: absolute
    width: 2px
    height: 6px
    top: -1px
    left: 3px
    background: $watch-color

a:hover
  >.watch
    border-color: $cloud6
    &::before, &::after
      background: $cloud6
