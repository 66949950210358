.main-nav__left
  display: flex

.navbar.main-nav
  height: $navbar-height
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.15)
  padding: 0
  position: absolute
  left: 0
  right: 0
  top: 0
  z-index: 1
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 385px)
    display: flex

.navbar-brand
  @include flex-vertically-align-center
  padding: 0
  height: $navbar-height
  margin-left: 15px
  &:focus
    border: 2px solid white
    outline: none
  img
    max-width: $navbar-logo-max-width
    max-height: $navbar-logo-max-height
.navbar-nav .dropdown-menu
  position: absolute
  margin-top: 0px
  border-top-left-radius: 0
  border-top-right-radius: 0
  width: 280px
  background-color: #fff
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.5)
  .dropdown-item
    font-size: 14px
    font-weight: 500
    color: $ninja10

.main-nav__customer-logo
  max-width: 100%
  max-height: 44px

.navbar-button
  background: none
  box-shadow: none
  border: 0
  width: $navbar-height
  height: $navbar-height
  @include flex-vertically-align-center
  text-align: center
  &.btn-secondary:not(:disabled):not(.disabled):active
    background-color: transparent
  &:hover,
  &:active
    img
      opacity: 0.75
  &:focus
    box-shadow: inset 0 0 0 2px rgba(255,255,255,0.75)
    outline: none

.nav-link.account-dropdown
  @extend .navbar-button
  padding: 0
  svg
    path
      fill: #fff
  &:hover,
  &:active
    svg
      opacity: 0.75
  + .dropdown-menu.show
    .dropdown-item
      position: relative
      .vst-spinner-container
        display: inline-block !important
        .vst-spinner
          width: 40px
.dropdown.show
  .nav-link.account-dropdown
    background: rgba(255,255,255,.13)

.main-nav .dropdown-menu
  margin-top: 0
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.30)

.nav-text-item
  color: #fff
  @extend .navbar-button
  width: auto
  svg
    path
      fill: #fff

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs)
  .navbar-brand
    img
      max-width: $navbar-sm-logo-max-width
      max-height: $navbar-sm-logo-max-height

@media (max-width: 385px)
  .navbar.main-nav
    display: flex !important
  .navbar-brand
    margin: 0
    img
      max-width: $navbar-xs-logo-max-width
      max-height: $navbar-xs-logo-max-height
