.current-timezone
  font-size: 1rem
  line-height: 1.5rem
  font-style: italic
  &__link
    color: $ocean6
    text-decoration: underline
    font-weight: 500
    &:hover
      text-decoration: underline
