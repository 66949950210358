.learning-objectives-wrapper
  display: flex
  border-radius: 4px
  margin-bottom: 40px
  margin-top: 20px

  > span
    display: flex
    width: 50px
    text-align: center
    img
      margin: 0 auto

  h2
    color: $ocean7
    margin-bottom: 25px
    font-size: 22px
    text-align: left

  .lo-icon
    left: 0


.lo-accordion
  background-color: #FFFFFF
  margin-bottom: 30px
  border: 1px solid $magic2
  border-radius: 5px

.lo-accordion__toggler
  background-color: #FFFFFF
  border: 0
  box-shadow: none
  text-align: left
  height: 78px
  vertical-align: middle
  width: 100%
  border-radius: 5px
  font-size: 18px
  color: $cloud9
  padding: 0 28px 0 20px
  line-height: 26px
  position: relative
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  display: flex
  align-items: center
  justify-content: space-between
  font-weight: 600
  &:hover,
  &:focus,
  &:active
    background: none
    color: $cloud9
  &:focus
    box-shadow: inset 0 0 0 2px $ninja10
    outline: 0

.lo-accordion__toggler--active
  .lo-accordion__toggler-icon
    transform: rotate(180deg)

.lo-accordion__toggler-icon
  transition: transform 0.5s

.lo-accordion__content
  border-top: 1px solid #ECECEC
  padding: 0 20px 0 20px
  .lo-list
    margin-top: 24px
    margin-bottom: 32px

.lo-list
  padding: 0
  padding-left: 44px
  margin: 0

.lo-item
  color: #000000
  font-size: 18px
  margin-bottom: 1rem
  &::marker
    color: $ocean7
  h2
    line-height: 22px
    font-size: 16px
    font-weight: normal


.lo-icon
  position: absolute
  left: 15px
  top: 12px