main
  &.external-module
    display: flex
    + .lx-footer
      background-color: #fafafa

.external-module-container
  max-width: none
  padding: 0
  flex-grow: 1
  .trial-alert
    margin: 20px
    @media (min-width: 768px)
      margin: 20px auto
      max-width: 720px
    @media (min-width: 992px)
      max-width: 960px
    @media (min-width: 1200px)
      max-width: 1140px

.external-module-iframe
  width: 100%
  height: 100%
  border: 0