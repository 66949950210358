.breadcrumbs
  margin-bottom: 8px

.breadcrumbs-list
  list-style: none
  margin: 0
  padding: 0
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: baseline

.breadcrumb-item
  display: flex
  padding-right: 10px
  color: $white
  &--module
    @include media-breakpoint-down(sm)
      display: none

.breadcrumb-item__link
  color: $white
  max-width: 250px
  text-decoration: none
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  &:hover
    color: $white
    text-decoration: underline

.breadcrumb-divider
  display: flex
  padding-right: 10px
  &--module
    @include media-breakpoint-down(sm)
      display: none

.breadcrumbs.light .breadcrumb-item
  color: $ninja10

.breadcrumbs.light .breadcrumb-item__link
  color: $ninja10
  text-decoration: none
  &:hover
    color: $ninja10
    text-decoration: underline

.page-location-bar 
  .breadcrumb-item
    font-size: 16px
  .breadcrumb-item__link
    font-size: 16px
    font-weight: bold
