

.form-inline 
    &.search-box
        position: relative
        input.form-control
            border-color: transparent
            border-radius: 50rem!important
            font-size: $font-size-sm
            font-weight: 400
            color: $ninja11
            width: 150px
            &::placeholder
                font-weight: 100
                color: $ninja11
            &:hover,
            &:active,
            &:focus
                outline: none
            @include media-breakpoint-up(lg)
                width: 280px
                border: 1px solid rgba(0,0,0,0.43)
                padding-left: 30px
                &:focus,
                &:active
                    border: 1px solid $ninja11
                    box-shadow: 0 0 0 1px $ninja11
                    border-color: #000
                    font-weight: 400
                    color: $ninja11
            @include media-breakpoint-down(md)
                box-shadow: none

.search-box__submit
    background-color: transparent
    border: 0
    position: absolute
    top: 4px
    left: 4px
    display: none
    @include media-breakpoint-up(lg)
        display: block
        top: 18px

.search-box__icon
    width: 15px
    path
        fill: $pickle5