.vst-spinner-container
  display: block !important // hide spinner until css is loaded
  text-align: center
  font-size: 0
  &.position-fixed
    position: fixed
    top: 50%
    left: 50%
    margin: -30px 0 0 -30px
  &.position-center
    position: static

.vst-spinner
  display: inline-block
  margin: auto
  width: 60px
  overflow: hidden

.vst-spinner__svg
  animation: spinner-spin 1s linear infinite


@keyframes spinner-spin
  100%
    transform: rotate(360deg)
