.author-toolbar
    position: fixed
    bottom: 20px
    right: 20px
    ul
        list-style: none
        margin: 0
        padding: 0
        li button
            background: white
            background-image: linear-gradient(90deg, #FFFFFF 0%, #F2F4F9 100%)
            border: 1px solid #D2D9ED
            width: 56px
            height: 56px
            border-radius: 0
            padding: 0 !important
            &:hover
              transform: scale(1.04)
              box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2)
        li:first-child button
            border-radius: 5px 5px 0 0
        li:last-child button
            border-radius: 0 0 5px 5px