.sidebar-layout
  &__content
    @include media-breakpoint-down(sm)
      margin-left: 15px

  &__card
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15)
    > h1
      font-size: 28px
      color: $ninja10
      padding: 20px 20px 0 20px
      margin-bottom: 10px

  &__form-description
    color: #596F85

  .nav
    .nav-link
      a
        color: #0D78A0