.workbook-page-iframe
  border: none
  width: 100%
  height: stretch
  height: -webkit-fill-available
  height: -moz-fill-available
  margin: auto
  display: block

.workbook-page-container
  padding: 2rem 0 0 0 !important
  @include media-breakpoint-down(xs)
    padding-right: 15px !important
    padding-left: 15px !important

.workbook-page-navigation .pagination

  .page-item:first-child .page-link
    margin-right: -1px

  .page-link
    height: 38px
    width: 66px
    border: 1px solid $magic2
    text-align: center
    .icon
      display: inline-block
    &.prev
      .icon
        transform: scaleX(-1)
        top: 1px
        position: relative
    &:hover, &:active
      border-color: #8093A6
    &:focus
      border-color: #8093A6
      box-shadow: 0 0 0 1px #8093A6
      z-index: 1
    &:disabled
      opacity: 0.65


  .page_number
    line-height: 24px
    text-align: center
    display: inline-block
    padding: 6px 12px
    border-top: 1px solid #ddd
    border-bottom: 1px solid #ddd

  input
    width: 70px
    position: relative
    text-align: center
    border-radius: 0
    -moz-appearance: textfield
    border-color: $gray-400
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      margin: 0
    &:focus
      z-index: 1

.workbook-page-navigation.top-pagination
  position: relative
  clear: both
  text-align: right
  nav
    float: right
  .pagination
    margin-bottom: 0
    margin-top: 4px

  @include media-breakpoint-up(md)
    clear: none

  @include media-breakpoint-down(md)
    text-align: center
    nav
      float: none
      display: inline-block

.workbook-page-navigation.bottom-pagination
  margin-top: 0rem
  text-align: center
  .pagination
    @extend .justify-content-center
    .page-link
      height: 48px
      padding-left: 15px
      padding-right: 15px
      &.next, &.prev
        width: 100px
      .icon
        position: relative

.iframe-placeholder
  height: auto
  .vst-spinner
    display: none

  &.loading
    height: calc(100vh - 350px)
    width: 100%
    overflow: hidden
    .vst-spinner
      display: block
      position: absolute
      left: 50%
      top: 45%

.workbook-page-footer
  background-color: #f2f4f9
  padding: 2rem 0
  border-top: 1px solid #d5d5e1
  font-size: 18px
  .pagination
    margin-bottom: 0
  a.home
    float: left
    display: inline-block
    border-right: 1px solid #d5d5e2
    height: 20px
    line-height: 35px
    padding-right: 15px
    margin-right: 15px
    position: relative
    top: 8px
    img
      top: -9px
      position: relative

  input[type='number']
    width: 70px
    display: inline-block
    text-align: center
    -moz-appearance: textfield
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      -webkit-appearance: none
      -moz-appearance: none
      appearance: none
      margin: 0

  .page-link
    padding: 0.75rem 1.25rem
    font-weight: 500
    span
      margin: 0 5px

.content-not-available
  text-align: center
  &--page
    background-image: url("../../images/skeleton.svg")
    background-size: 112%
    background-position: center -20px
    background-repeat: no-repeat
    height: 500px
    @include media-breakpoint-up(md)
      height: 750px
    .content-not-available__message
      padding-top: 180px
  &__message
    margin: 0 auto
    @include media-breakpoint-up(md)
      width: 454px
  &__title
    color: $ninja9
    font-size: 24px
    line-height: 36px
    margin-bottom: 8px
  &__details
    color: $ninja9
    font-size: 18px
    line-height: 27px
  &__button
    margin-top: 10px !important