.signin
  &__container
    margin: 0 auto
    padding-top: 30px
    padding-bottom: 30px
    @include media-breakpoint-up(md)
      width: 400px
  &__form
    border-radius: 0
    @include media-breakpoint-up(md)
      border-radius: 5px
    .form__submit-btn
      margin-top: 10px
    .form__recaptcha-form-group
      + .form__submit-btn
        margin-top: 0
    .form__links
      li
        text-align: right
        + li
          text-align: left
  &__customer-logo-container
    width: 250px
    height: 54px
    margin: 0 auto 20px
    display: flex
    align-items: center
    justify-content: center
    &--two-logos
      width: auto
      justify-content: space-between
      position: relative
      .separator
        background-color: #B2BFE0
        width: 1px
        align-self: stretch
      .signin__customer-logo
        display: flex
        &:first-child
          max-width: 50%
        &:last-child
          width: 145px
          position: relative
          top: 2px
  &__customer-logo-link
    height: 54px
    display: flex
    align-items: center
    justify-content: center
  &__customer-logo
    display: block
    max-width: 100%
    max-height: 100%
  .form__links
    margin-top: 20px
    margin-bottom: 30px
.signup
  &__password-rules
    font-size: 14px
    color: #596F85
    line-height: 22px
    margin: 8px 0 0 0
    &.text-danger
      font-weight: 500
