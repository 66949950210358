@import 'node_modules/bootstrap/scss/variables'

$alert-bg: #fff2f4
$alert-border: #f1173d

.alerts-panel
    @extend %primary-item

    &__danger
        border-color: $alert-border
        border-width: 2px
        border-style: solid
        border-top-left-radius: 6px
        border-top-right-radius: 6px
        .toc__element-wrapper
            background-color: $alert-bg

    &__title
        font-weight: bold
        &::before
            right: 80px !important

    &__assignment-list
        margin-bottom: 0
        list-style: none
        padding: 0
        li
            border-top: 1px solid #ccc
            padding: 20px
        .unit-module
            font-size: .85rem
            font-weight: normal
        .assignment-title
            span
                display: inline-block
            a
                text-decoration: underline
                font-size: 1.25rem


    &__assignment-icon
        margin-right: 10px
        top: -2px
        position: relative

.assignment-schedule
    font-size: 14px
    color: $cloud6
    line-height: 17px
    font-weight: normal
    margin-bottom: 0
    &.danger
        color: $alert-border
        path
            fill: $alert-border