.assignment-table
  .ag-root-wrapper
    border-radius: 4px
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2)
    border: none

  .ag-header-container,
  .ag-center-cols-container
    width: 100% !important

  .ag-header-row
    width: 102% !important

  .ag-header-cell-text
    color: #525252
    font-family: 'Roboto'
    font-weight: 400
    font-size: 14px

  .ag-keyboard-focus .ag-header-cell:focus::after
    border-color: #2196f3

  .ag-icon-desc,
  .ag-icon-asc
    &::before
      content: "" !important
      background-image: url("../../images/small-arrow-up.svg")
      background-repeat: no-repeat
      transform: translateY(-50%)
      position: absolute
      width: 14px
      height: 14px
      right: 7px

  .ag-icon-desc
    &::before
      transform: translateY(-50%) scale(-1)

  // .ag-checkbox-input-wrapper
  //   &.ag-indeterminate
  //     &::after
  //       color: $ocean6

  .ag-row
    font-family: 'Roboto'
    font-weight: 400
    font-size: 16px

  .ag-react-container
    text-align: left
    vertical-align: center
    height: 100%

    > span
      display: inline-block
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      width: 100%

  .ag-checkbox-input
    &:hover
      cursor: pointer

  .inherited_date
    color: #999999
    font-style: italic
    text-decoration: underline
    text-decoration-style: dotted
    text-underline-offset: 5px

  .assignment-table
    width: 100%
    background: transparent
    border: none
    padding: 0
    display: inline-block
    // pointer-events: none
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &.module
      > span
        margin-left: 24px

    &__cel-link
      width: calc(100% - 48px)
      margin-left: 48px
      font-weight: 500
      text-align: left
      text-decoration: underline
      background: transparent
      border: none
      padding: 0
      display: inline-block
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    > span
      pointer-events: all

  .unit-module-edit
    background-color: transparent
    border: none
    padding: 0
    margin: 0

    svg
      fill: black
      height: 16px
      width: 16px

  .edit-action-cell
    transform: translateX(-100%)
    width: 50px !important

  .no-pointer-events
    pointer-events: none

  .ag-row-selected
    background-color: transparent

  .ag-row-odd
    background-color: #ffffff
  // .ag-row-selected.ag-row-odd
  //   background-color: #fcfcfc

  .disabled-row
    background-color: #f6f6f6
    &.ag-row-hover
      background-color: #f6f6f6 !important

  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group
    padding-left: 16px !important
    padding-right: 16px !important
    line-height: 56px
    display: flex

  .ag-overlay-no-rows-center,
  .ag-overlay-loading-center
    border: none
    box-shadow: none
    font-weight: 500
    color: #2F2F39
    font-family: Roboto
    font-size: 20px
    line-height: 24px

  .no-border
    border: none !important

  // Theming
  &.ocean

    .unit-module-edit
      svg
        fill: $ocean6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $ocean6

    .assignment-table__cel-link
      color: $ocean6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $ocean6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $ocean1

    .ag-row-hover
      background-color: $ocean1

    .ag-header-row
      background-color: $ocean2

    .ag-header-cell-resize
      &::after
        background-color: $ocean3

  &.cheddar

    .unit-module-edit
      svg
        fill: $cheddar6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $cheddar6

    .assignment-table__cel-link
      color: $cheddar6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $cheddar6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $cheddar1

    .ag-row-hover
      background-color: $cheddar1

    .ag-header-row
      background-color: $cheddar2

    .ag-header-cell-resize
      &::after
        background-color: $cheddar3

  &.magic

    .unit-module-edit
      svg
        fill: $magic6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $magic6

    .assignment-table__cel-link
      color: $magic6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $magic6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $magic1

    .ag-row-hover
      background-color: $magic1

    .ag-header-row
      background-color: $magic2

    .ag-header-cell-resize
      &::after
        background-color: $magic3

  &.sangria

    .unit-module-edit
      svg
        fill: $sangria6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $sangria6

    .assignment-table__cel-link
      color: $sangria6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $sangria6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $sangria1

    .ag-row-hover
      background-color: $sangria1

    .ag-header-row
      background-color: $sangria2

    .ag-header-cell-resize
      &::after
        background-color: $sangria3

  &.jungle

    .unit-module-edit
      svg
        fill: $jungle6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $jungle6

    .assignment-table__cel-link
      color: $jungle6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $jungle6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $jungle1

    .ag-row-hover
      background-color: $jungle1

    .ag-header-row
      background-color: $jungle2

    .ag-header-cell-resize
      &::after
        background-color: $jungle3

  &.pickle

    .unit-module-edit
      svg
        fill: $pickle6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $pickle6

    .assignment-table__cel-link
      color: $pickle6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $pickle6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $pickle1

    .ag-row-hover
      background-color: $pickle1

    .ag-header-row
      background-color: $pickle2

    .ag-header-cell-resize
      &::after
        background-color: $pickle3

  &.brick

    .unit-module-edit
      svg
        fill: $brick6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $brick6

    .assignment-table__cel-link
      color: $brick6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $brick6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $brick1

    .ag-row-hover
      background-color: $brick1

    .ag-header-row
      background-color: $brick2

    .ag-header-cell-resize
      &::after
        background-color: $brick3

  &.mist

    .unit-module-edit
      svg
        fill: $mist6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $mist6

    .assignment-table__cel-link
      color: $mist6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $mist6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $mist1

    .ag-row-hover
      background-color: $mist1

    .ag-header-row
      background-color: $mist2

    .ag-header-cell-resize
      &::after
        background-color: $mist3

  &.eggplant

    .unit-module-edit
      svg
        fill: $eggplant6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $eggplant6

    .assignment-table__cel-link
      color: $eggplant6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $eggplant6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $eggplant1

    .ag-row-hover
      background-color: $eggplant1

    .ag-header-row
      background-color: $eggplant2

    .ag-header-cell-resize
      &::after
        background-color: $eggplant3

  &.cloud

    .unit-module-edit
      svg
        fill: $cloud6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $cloud6

    .assignment-table__cel-link
      color: $cloud6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $cloud6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $cloud1

    .ag-row-hover
      background-color: $cloud1

    .ag-header-row
      background-color: $cloud2

    .ag-header-cell-resize
      &::after
        background-color: $cloud3

  &.frost

    .unit-module-edit
      svg
        fill: $frost6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $frost6

    .assignment-table__cel-link
      color: $frost6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $frost6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $frost1

    .ag-row-hover
      background-color: $frost1

    .ag-header-row
      background-color: $frost2

    .ag-header-cell-resize
      &::after
        background-color: $frost3

  &.ninja

    .unit-module-edit
      svg
        fill: $ninja6

    .ag-checkbox-input-wrapper
      &.ag-indeterminate
        &::after
          color: $ninja6

    .assignment-table__cel-link
      color: $ninja6

    .ag-checkbox-input-wrapper.ag-checked
      &::after
        color: $ninja6 !important

    .ag-row-selected
      &.ag-row-hover
        background-color: $ninja1

    .ag-row-hover
      background-color: $ninja1

    .ag-header-row
      background-color: $ninja2

    .ag-header-cell-resize
      &::after
        background-color: $ninja3

  // If we can dynamically access variables we could do this:
  // $themeMap: ('ocean': 'ocean', 'cheddar': 'cheddar')
  // @each $themeName, $actualTheme in $themeMap
  //   &.#{$actualTheme}
  //     .assignment-table__cel-link
  //       color: #{$actualTheme}6
