.allow-late-work-switch
  display: flex
  justify-content: flex-end
  align-items: center
  .label
    margin-right: 16px
    white-space: nowrap
    display: flex
    align-items: center
    svg
      width: 20px
      height: 20px
      fill: $cloud6
      margin-right: 8px
