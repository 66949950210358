.course-alerts
    overflow: hidden
    overflow-x: auto
    margin-bottom: 3rem
    height: 155px
    ul
        padding: 0

.alert-card
    border: 1px solid #DADADA
    border-radius: 6px
    width: 262px
    height: 146px
    float: left
    list-style: none
    margin-right: 20px
    position: relative
    padding: 14px 20px
    overflow: hidden

.alert-card__bg
    position: absolute
    width: 100%
    z-index: -1
    top: 0
    left: 0

.alert-card__content-progress
    position: absolute
    width: 50px
    height: 50px
    text-align: center
    line-height: 50px
    border-radius: 50px
    box-shadow: 0 2px 3px rgba(0,0,0,.5)
    background-color: $white
    top: 19px
    right: 21px

.alert-card__status
    font-weight: 700
    font-size: 11px
    color: $white
    margin-bottom: 26px

.alert-card__schedule
    font-size: 11px
    color: $cloud6
    text-align: left
    line-height: 11px
    margin-bottom: 13px
    svg
        width: 11px
        height: 11px
        position: relative
        top: -1px
        margin-right: 3px
        path
            fill: $cloud6
    &.danger
        color: $red7
        svg
            path
                fill: $red7

.alert-card__title
    font-weight: 700
    font-size: 14px
    color: $cloud10
    letter-spacing: 0
    line-height: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    margin-bottom: 10px

.alert-card__activities
    .lx-pill
        margin-right: 10px

.alert-card__activity-points
    @extend .alert-card__content-progress
    background-color: $cloud9
    font-weight: 800
    font-size: 17px
    color: $white
    text-align: center
    line-height: 50px
    position: absolute
.alert-card__activity-completed
    position: absolute
    bottom: 0px
    left: 0px
    width: 14px