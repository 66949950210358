.book-reader
  overflow: hidden
  opacity: 1
  transition: opacity 0.2s
  &.loading
    opacity: 0
    transition: none

.learnkit-page
  position: relative
  .vst-spinner
    position: absolute
    top: 10px
    left: 50%
    margin-left: -35px
    margin-top: 0
  + .iframe-placeholder
    margin-top: 2rem

.learnkit-citation
  margin-top: 20px
  a
    font-size: 14px
    color: #8093a6
    text-decoration: none
    &:hover
      color: #002244
      text-decoration: underline
