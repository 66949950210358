$module-bg-color: #ffffff;
$module-border-color: transparent;
$titles-color: $magic10;
$primary-item-height: 80px;

@mixin cut-text-with-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%toc-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.toc__list {
  &--unit {
    @extend %toc-list;
  }
  &--module {
    @extend %toc-list;
  }
  &--page {
    @extend %toc-list;
  }
  &--skeleton {
    @extend %toc-list;
    opacity: 0.75;
  }
}

%toc-list-item {
  position: relative;
}
.toc__list-item {
  &--unit {
    @extend %toc-list-item;
  }
  &--module {
    @extend %toc-list-item;
  }
  &--page,
  &--bottom,
  &--assignment {
    @extend %toc-list-item;
    &:focus-within {
      box-shadow: inset 0 0 0 2px $ninja10;
      outline: 0;
    }

    &:focus-within .toc__link--page:focus,
    &:focus-within .toc__link--assignment:focus {
      box-shadow: none;
      outline: 0;
    }
  }
  &--not-available {
    .toc__link,
    .toc__metrics-list {
      color: $cloud6 !important;
    }
  }
  &--skeleton {
    @extend %toc-list-item;
    padding: 20px;
  }
}

.toc__element-wrapper {
  @extend .d-flex;
}

.toc__link {
  flex-grow: 1;
  display: block;
  text-decoration: none;
  @include cut-text-with-ellipsis;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px $ninja10;
    outline: 0;
  }
  &--page {
    flex-grow: 1;
  }
  &-title {
    @include cut-text-with-ellipsis;
  }
}
.toc__link-label {
  font-weight: 700;
}

.toc__toggler {
  text-align: center;
  color: transparent;
  overflow: hidden;
  cursor: pointer;
  &:focus {
    box-shadow: inset 0 0 0 2px $ninja10;
    outline: 0;
  }
}
.toc__toggler-icon {
  transition: transform 0.5s;
}
.toc__toggler--open {
  .toc__toggler-icon {
    transform: rotate(180deg);
  }
}

.toc__completion {
  display: block;
  text-align: center;
  position: absolute;
  font-size: 0;
}

.toc__metrics-list {
  @extend .align-items-center;
  list-style: none;
  padding: 0;
  margin: 0;
  color: $cloud8;
  font-size: 12px;
  line-height: 11px;
  display: none;
  text-align: center;
  height: $primary-item-height;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}
.toc__metrics-list-item {
  min-width: 100px;
  text-transform: capitalize;
}
.toc__metrics-value {
  font-size: 35px;
  line-height: 35px;
  display: block;
  margin-bottom: 5px;
  font-weight: 900;
}

.toc__list-item--assignment {
  flex-direction: column;
  justify-content: center;
  .toc__link {
    height: auto !important;
  }
}
.toc__assignment-label {
  display: block;
  margin-bottom: 5px;
}
.assignment_label_data_wrapper {
  margin-top: 29px;
  margin-bottom: 27px;
  display: block;
}
.toc__metadata {
  @extend .list-inline;
  width: 100%;
  padding-left: 30px;
  line-height: 15px;
  margin-bottom: 0px;
  margin-top: 8px;
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
.toc__metadata-item {
  display: inline-block;
  font-size: 14px;
  color: $cloud6;
  line-height: 17px;
  position: relative;
  padding: 0 10px;
  &:first-child {
    padding-left: 0;
    &:before {
      content: none;
    }
  }
  &:before {
    content: '|';
    color: $magic2;
    position: absolute;
    left: 0;
  }
  .danger {
    color: $red6;
    .toc__metadata-icon {
      path {
        fill: $red6;
      }
    }
  }
}
.toc__metadata-icon {
  position: relative;
  top: -2px;
  margin-right: 4px;
  path {
    fill: $cloud6;
  }
}

%primary-item {
  background: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 20px;
  min-height: $primary-item-height;
  .collapse {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      &:not(.show) {
        display: none;
      }
    }
  }
  .toc__element-wrapper {
    @extend .align-items-center;
    height: $primary-item-height;
    position: relative;
    min-width: 0;
    flex-grow: 1;
  }
  .toc__completion {
    position: absolute;
    top: 40px;
    left: 20px;
    + .toc__link {
      padding-left: 90px;
    }
  }
  .toc__link {
    font-size: 16px;
    color: $cloud9;
    padding-left: 20px;
    padding-right: 15px;
    height: $primary-item-height;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .toc__toggler {
    min-width: 80px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  .toc__toggler-icon {
    transition: transform 0.5s;
  }
  .toc__toggler--open {
    .toc__toggler-icon {
      transform: rotate(180deg);
    }
  }
}

%secondary-item {
  min-height: 80px;
  border-top: 1px solid $magic2;
  display: flex;
  .toc__link {
    font-size: 18px;
    color: $cloud9;
    padding-left: 20px;
    padding-right: 20px;
    @include media-breakpoint-up(md) {
      padding-left: 48px;
    }
  }
}

.course-dashboard {
  &--course {
    .toc__list-item--unit {
      @extend %primary-item;
    }
    .toc__list-item--module {
      @extend %secondary-item;
    }
    .toc__link.toc__link--unit {
      &:before {
        right: 80px;
      }
    }
  }
  &--unit {
    .toc__list-item--module {
      @extend %primary-item;
      display: flex;
      flex-direction: column;
    }
    .toc__list-item--page,
    .toc__list-item--assignment {
      @extend %secondary-item;
    }
    .toc__link.toc__link--module {
      &:before {
        right: 80px;
      }
    }
  }
  &--module {
    .toc__list-item--module {
      @extend %primary-item;
      .toc__link-title {
        font-size: 16px;
        display: block;
        padding: 0 15px 0 0;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
      .collapse {
        display: block;
      }
    }
    .toc__list-item--page,
    .toc__list-item--assignment {
      @extend %secondary-item;
      &:focus-within {
        box-shadow:
          0 1px 3px 0 rgba(0, 0, 0, 0.15),
          inset 0 0 0 2px $ninja10;
        outline: 0;
      }
      .toc__completion {
        display: block;
      }
      .toc__link {
        font-size: 16px;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
    }
    .toc__page-number {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
  .toc__list-item--skeleton {
    @extend %primary-item;
    &:after {
      content: '';
      display: block;
      height: 40px;
      width: 50%;
      background-color: $magic1;
      opacity: 0.504563105;
    }
    &:first-child {
      &:after {
        width: 30%;
      }
    }
    &:last-child {
      &:after {
        width: 40%;
      }
    }
  }
  .toc__list-item--module {
    .toc__completion {
      margin-top: -25px;
    }
  }
  .toc__list-item--page,
  .toc__list-item--assignment {
    @extend .d-flex;
    .toc__completion {
      width: 30px;
      height: 30px;
      margin-top: -16px;
      left: 30px;
      ~ .toc__link {
        padding-left: 80px;
      }
      + .toc__assignment-icon {
        left: 80px;
      }
    }
  }
  .toc__list-item--assignment {
    .toc__completion {
      left: 30px;
      ~ .toc__link {
        padding-left: 85px;
        flex-grow: unset;
        width: 100%;
        min-height: none;
        line-height: 24px;
        height: auto;
      }
    }
    @include media-breakpoint-up(md) {
      .toc__link {
        padding-left: 48px;
      }
    }
  }
  .toc__link {
    height: $primary-item-height;
    // line-height: $primary-item-height;
    display: flex;
    justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    > div > span {
      display: flex;
      gap: 10px;
      @include cut-text-with-ellipsis;
    }
  }
  .toc__page-number {
    min-width: 80px;
    width: 80px;
    text-align: center;
  }
  .toc__assignment-icon {
    margin-top: 3px;
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

button.btn-external-module-schedule {
  background: none;
  flex-shrink: 0;
  border: 0;
  border-radius: 0;
  height: 80px;
  width: 80px;
  position: relative;
  z-index: 1;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: scale(1);
  }
  &:hover:not(:disabled) {
    box-shadow: inset 0 0px 3px 0 $magic2;
  }
  &:focus:not(:disabled) {
    box-shadow: inset 0 0 0 2px $ninja10;
    outline: 0;
  }
}

.reset-assessments-button {
  line-height: 24px;
  svg {
    width: 20px;
    path {
      fill: #ffffff;
    }
  }
  &[disabled] {
    svg path {
      fill: #333;
    }
  }
}

.toc__label {
  align-self: flex-start;
  background-color: $cloud3;
  border-radius: 16px;
  color: $cloud7;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  padding: 3px 12px;
}

.toc__content-availability-statement {
  color: $cloud10;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 40px;
}

.toc--skeleton {
  position: relative;
  .content-not-available {
    text-align: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 90px;
    left: 0;
    right: 0;
    padding: 0 20px;
  }
}
