.page-mast
  margin-bottom: 50px
  overflow: hidden
  z-index: 1
  width: 100%
  padding-top: 30px
  @include media-breakpoint-down(sm)
    padding-left: 15px

.page-mast__title
  color: $white
  font-weight: 400
  font-size: 2.25rem
  line-height: 2.75rem
  color: #fff
  letter-spacing: 0
  margin-bottom: 30px
  @include media-breakpoint-down(sm)
    font-size: 1.75rem
    line-height: 2.125rem