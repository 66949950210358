html
  scroll-behavior: smooth
  overflow-y: scroll

@supports (scrollbar-gutter: stable)
  html
    overflow-y: auto
    scrollbar-gutter: stable

body
  margin: 0
  padding: 0
  @include media-breakpoint-up(md)
    min-height: calc(100vh - 107px) // calc(100vh - $footer-height - $footer-margin-top)

.skip-to-main,
.skip-to-main:active
  position: fixed !important
  top: 15px
  left: -999px
  z-index: 1001
  &:focus
    left: 15px

a:hover
  text-decoration: none

.list-none,
.list-none__li
  display: inline-block
  list-style: none
  margin: 0
  padding: 0

.h1
  font-size: 28px
  font-weight: 600
  line-height: 35px
  margin-bottom: 20px
  color: $magic10
  &--bordered
    border-bottom: 2px solid $magic10

.h1--small
  font-size: 21px
  font-weight: 600
  line-height: 30px
  margin-bottom: 20px
  padding-bottom: 18px
  padding-top: 10px
  color: $magic10
  &--bordered
    border-bottom: 2px solid $magic10

.btn-outline-secondary
  border-color: $magic3
  color: $magic10
  font-weight: 500

button.btn,
a.btn
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.1s ease-in
  &:hover:not(:disabled),
  &:focus:not(:disabled)
    transform: scale(1.04)
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.20)

.btn-primary
  background-color: $magic6
  border: none
  color: $white
  font-weight: 500

.modal-signin a.btn.btn-primary:visited
  background-color: inherit

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:visited
  background-color: $magic7

.alert-danger
  color: $sangria8
  background-color: $sangria1
  border-color: $sangria6

.alert-success
  color: #048a04
  background-color: #Edf7ed
  border-color: #048a04

.external-link
  svg
    margin-left: 5px
    font-size: 18px
    font-weight: 600
    margin-top: -3px
