.drawer
  .drawer-body
    padding: 10px 24px

  table
    tr
      th
        font-weight: normal
        padding-left: 0

    td
      font-weight: bold
      word-break: break-word

  .button-icon
    font-size: 20px
    margin-right: 10px
    font-weight: bold

  .wrapper
    position: relative
    display: flex
    flex-direction: column
    height: 100%
    width: 100%

    .bottom-actions
      button
        margin-right: 16px
        & + button
          margin-right: 0

  .header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 20px 24px
    border-bottom: 2px solid #DADADA

    .title
      font-family: 'Roboto'
      color: #1C1C1C
      font-size: 18px
      line-height: 27px
      text-align: left
      font-weight: 500
      padding-right: 20px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap


    button
      border: none
      background: none
      padding: 0
      margin: 0
    svg
      fill: #757575
      height: 24px
      width: 24px

  .bottom-actions
    z-index: 5
    position: sticky
    bottom: 0
    background-color: #FFF
    padding: 20px 24px
    width: 100%
    margin-top: auto
    border-top: 1px solid #DADADA
    box-shadow: 0px -4px 10px 0px #0000001a

  // SmartForm styles
  .form-controller
    display: flex
    height: 100%
    flex-direction: column
    position: relative
    overflow-y: auto
    overflow-x: hidden

    @-moz-document url-prefix()
      // adds extra padding below last form-module for Firefox.
      >div:nth-last-child(2)
        padding-bottom: 170px

    .content
      display: flex
      height: 100%
      flex-direction: column
      position: relative
      overflow-y: auto
      overflow-x: hidden
      padding: 20px 24px

    .form-error
      margin: 10px 24px
      display: flex
      background-color: #FFE1E6
      border-radius: 4px

      >div
        margin: 16px

      svg
        height: 18px
        width: 18px
        fill: #1c1c1c
        transform: rotate(180deg)

      span
        color: #1B1B26
        font-family: Roboto
        font-size: 16px
        line-height: 24px
        margin: 16px 16px 16px 0
        text-align: left

    .form-module
      padding: 10px 24px
      label
        font-size: 16px

    > fieldset
      background-color: #F2F4F9
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15)
      border-radius: 5px
      margin: 24px
      + fieldset
        margin-top: 0
      .MuiInputBase-root
        background: #ffffff

    .attempts-time-limit
      display: flex
      flex-direction: row
      justify-content: space-between
      > div:not(:first-child)
        padding-left: 5px
      > div:not(:last-child)
        padding-right: 5px

    .course-title
      padding-top: 24px
      > div
        margin-bottom: 0
    input[type="text"]
      width: 100%

    .date-time
      .select
        margin-bottom: 10px
      .pickers
        display: flex
        justify-content: space-between
        > div:not(:first-child)
          padding-left: 5px
        > div:not(:last-child)
          padding-right: 5px

      .date-picker
        width: 50%
      .time-picker
        width: 50%

    .student-availability-check,
    .preserve-instructors-check
      display: flex
      align-items: center

      .info-icon
        width: 20px
        height: 20px
        fill: #646464
        margin-left: 5px
        transform: rotate(180deg)

  .exceptions
    width: 100%
    margin-top: 20px
    padding: 10px 24px

    .form-module
      transition: opacity 200ms ease

    button
      font-weight: 500
      font-size: 16px
      line-height: 24px
      margin-bottom: 0

    .set-exceptions-btn
      width: 100%

    .exceptions-header
      display: flex
      width: 100%
      justify-content: space-between

      h3
        white-space: nowrap
        color: #1C1C1C
        font-family: Roboto
        font-size: 18px
        line-height: 27px
        margin: 0


  .form-description
    padding: 16px 24px 10px
    p
      font-size: 16px
      line-height: 24px
      &:last-child
        margin-bottom: 0
      a
        text-decoration: underline
        font-weight: 500
        &:hover
          text-decoration: underline

  .current-timezone
    padding: 22px 24px

  .settings-summary
    padding: 10px 24px

    h3
      white-space: nowrap
      color: #1C1C1C
      font-family: Roboto
      font-size: 18px
      line-height: 27px
      margin: 0
      padding-bottom: 10px

    .settings-row
      padding: 2px 0

.hide
  display: none
