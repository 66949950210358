body
  background-color: $main-bg-color
  padding-right: 0 !important

main
  padding-top: $navbar-height
  min-height: calc(100vh - #{$footer-height-sm} - #{$footer-with-navigation-margin-top} - #{$navbar-height})
  @include media-breakpoint-up(md)
    min-height: calc(100vh - #{$footer-height-md} - #{$footer-with-navigation-margin-top} - #{$navbar-height})
  &.overview
    background-color: #f2f3f8
    min-height: calc(100vh - #{$footer-height-sm})
    @include media-breakpoint-up(md)
      min-height: calc(100vh - #{$footer-height-md})
    + .lx-footer
      background-color: #f2f3f8
  &.full-page
    min-height: 100vh
    padding: 0
    transition: background-color 0.5s ease
  &.external-module
    min-height: calc(100vh - #{$footer-height-sm})
    @include media-breakpoint-up(md)
      min-height: calc(100vh - #{$footer-height-md})

#toasts-wrapper
  position: fixed
  top: 30px
  right: 30px
  z-index: $zindex-modal + 1
