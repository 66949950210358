.alert
  &.has-icon
    position: relative
    padding-left: 50px
    svg
      position: absolute
      left: 16px
      top: 15px
      height: 20px
      width: 20px

.form
  .alert
    line-height: 24px