.module-participation-score__row
  height: 90px
  line-height: 90px
  border-bottom: 1px solid $magic2
  position: relative
  top: -50px

.module-participation-score__list
  list-style: none
  padding: 0

.module-participation-score__stat
  display: none
  margin-right: 30px
  color: $cloud9
  font-size: 18px
  line-height: 30px
  text-transform: lowercase
  @include media-breakpoint-up(md)
    display: inline-block
  &:first-child
    display: inline-block
  &:last-child
    margin-right: 0
  .donut-chart
    display: inline-block
    margin-right: 6px
    position: relative
    top: 5px
    .chart-center
      background-color: $main-bg-color !important

.module-participation-score__stat-value
  font-weight: 900
  font-size: 26px
  line-height: 21px
  margin-right: 4px
