
.assignment-settings-page
  .assignment-settings-header
    display: flex
    align-items: center
    @extend .page-header-with-selector

    .courses-mast__title
      margin-right: 16px

    .info-icon
      fill: white
      height: 24px
      width: 24px
      margin-left: 16px

  .details-row
    display: flex
    flex-wrap: wrap
    padding: 0 0 20px 0
    flex-direction: row

    .title
      color: #2F2F39
      font-family: 'Roboto'
      font-weight: bold
      font-size: 24px
      line-height: 36px
      text-align: left
      padding-bottom: 8px

      @media (min-width: 576px)
        display: flex
        align-items: center
        flex-basis: 100%

      > span
        margin-right: 10px
        margin-bottom: 16px
        display: block

        @media (min-width: 576px)
          display: inline-block
          margin-bottom: 0

      > svg
        width: 20px
        height: 20px
        fill: $cloud6
        margin-left: 12px

    .details
      padding-right: 40px
      display: flex
      flex-direction: column

      ul
        padding-left: 1rem
      li
        line-height: 27px
        color: #2F2F39
        &::marker
          font-size: 0.9em
        svg
          width: 24px
          height: 24px
          fill: #757575
          margin-left: 10px
          position: relative
          top: -3px

      @media (min-width: 992px)
        flex: 3

      .body
        // text-align: justify
        color: #2F2F39
        font-family: Roboto
        font-size: 18px
        line-height: 27px

    .late-work
      @media (min-width: 992px)
        flex: 1

      span
        color: #2F2F39
        font-family: 'Roboto'
        font-size: 16px
        font-weight: 500
        line-height: 19px
        text-align: left

  .filters-row
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    align-items: flex-end

    > div
      margin: 10px 0
    @media (min-width: 1200px)
      > div
        margin: 0

  .table-row
    display: flex
    padding: 0 0 20px 0
