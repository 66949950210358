
.lx-pill.badge
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.50)
    border-radius: 12px
    background-color: #fff
    color: $cloud6
    font-weight: 500
    line-height: 24px
    padding: 0 9px
    svg
        path
            // fill: $cloud6
    &.lx-pill__pin
        svg 
            width: 10px
            margin-right: 3px
            position: relative
            top: -1px
            path
                fill: $cloud6
    &.lx-pill__quiz
        svg
            width: 12px
            position: relative
            margin-right: 5px
            top: -1px
            path
                fill: $cloud6
    &.lx-pill__unit
        svg
            height: 12px
            margin-right: 4px
            position: relative
            top: -1px
            path.filled 
                fill: $cloud6
