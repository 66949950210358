$menu-width: 360px;
$toggler-width: 47px;
$unitHeight: 66px;

.toc__logo-bar {
  background-color: $ninja10;
  height: 60px;
  color: $white;
  @extend .d-flex;
  @extend .align-items-center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.toc__logo-bar-button {
  all: unset;
  background: none;
  border: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  &:hover,
  &:active {
    opacity: 0.75;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75);
    outline: 0;
  }
}
.toc__logo-bar-link {
  display: block;
  max-width: 250px;
  margin-left: 15px;
  &:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75);
    outline: 0;
  }
}
.toc__logo {
  max-width: $navbar-logo-max-width;
  max-height: $navbar-logo-max-height;
  height: auto;
  @include media-breakpoint-down(xs) {
    max-width: $navbar-sm-logo-max-width;
    max-height: $navbar-sm-logo-max-height;
  }
  @media (max-width: 385px) {
    max-width: $navbar-xs-logo-max-width;
    max-height: $navbar-xs-logo-max-height;
  }
}

.menu-opened {
  overflow: hidden;
  .course-navigation-wrapper {
    visibility: visible;
  }
  .course-navigation {
    transform: translate3d(0, 0, 0);
  }
  .course-navigation__overlay {
    display: block;
  }
}

.course-navigation-wrapper {
  visibility: hidden;
  transition: visibility 0.2s;
}

.course-navigation {
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-stretch;
  @include custom-scrollbar(rgba(210, 217, 237, 0.5), transparent);
  padding: 0;
  background: $white;
  width: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-out;
  transform: translate3d(-100%, 0, 0);
  @media (min-width: 360px) {
    width: $menu-width;
    min-width: $menu-width;
  }
  .menus-container {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    @include custom-scrollbar(rgba(210, 217, 237, 0.5), transparent);
    &:hover {
      overflow-y: auto;
    }

    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .toc {
    @extend .flex-grow-1;
    width: 100%;
    min-width: 100%;
    @media (min-width: 360px) {
      width: $menu-width;
      min-width: $menu-width;
    }
  }
  .toc__link {
    color: $ninja11;
    max-width: calc(100% - #{$toggler-width});
    width: calc(100% - #{$toggler-width});
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(.toc__label) {
        width: 100%;
      }
      > span {
        grid-gap: 10px;
        gap: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-items: center;
        &:not(.toc__label) {
          width: 100%;
        }
      }
    }
    .toc__metadata-item {
      font-size: 12px;
      line-height: 16px;
      white-space: initial;
    }
  }
  .toc__metadata {
    padding-left: 28px;
  }
  .toc__toggler {
    width: $toggler-width;
  }
  .toc__list--unit {
    height: calc(100vh - 119px);
    width: 100%;
  }
  .toc__list-item--unit {
    border-top: 1px solid $magic2;
    width: 100%;
    &:first-child {
      border-top: none;
    }
    @media (min-width: 360px) {
      width: $menu-width;
      min-width: $menu-width;
    }
  }
  .toc__element-wrapper--unit {
    min-height: $unitHeight;
    .toc__toggler {
      padding: 0 5px 0 0;
      height: $unitHeight;
      line-height: $unitHeight;
    }
  }
  .toc__link--unit {
    padding: 0 10px 0 15px;
    font-size: 16px;
    min-height: $unitHeight;
    line-height: $unitHeight;
    > .toc__label {
      margin-top: 0;
      margin-bottom: 5px;
      position: relative;
      top: -13px;
    }
    > .toc__metadata {
      position: relative;
      top: -14px;
      margin-bottom: 5px;
    }
  }
  .toc__list--module {
    border-top: 1px solid $magic2;
    .toc__completion {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      left: 15px;
      top: 14px;
    }
    .toc__toggler {
      padding: 10px 5px 0 0;
    }
    .toc__link-label {
      font-weight: 400;
    }
  }
  .toc__list-item--module {
    border-top: 1px solid #e8ecf6;
    &:first-child {
      border-top: 0;
    }
  }
  .toc__link--module {
    padding: 0 7px 0 20px;
    min-height: 44px;
    line-height: 44px;
    font-size: 14px;
    > .toc__label {
      margin-top: 0;
      margin-bottom: 12px;
      position: relative;
      top: -2px;
    }
    > .toc__metadata {
      position: relative;
      top: -5px;
      margin-bottom: 8px;
    }
  }
  .toc__completion + .toc__link--module {
    padding: 0 7px 0 38px;
  }
  .toc__list--page {
    .toc__completion {
      width: 10px;
      height: 10px;
      border-radius: 8px;
      left: 18px;
      top: 12px;
    }
  }
  .toc__list-item--page,
  .toc__list-item--assignment {
    @extend .d-flex;
    font-size: 13px;
    position: relative;
    color: $cloud10;
    padding: 5px 0;
    &.toc__list-item--not-available {
      .toc__assignment-icon {
        top: 16px;
      }
    }
  }
  .toc__link--page,
  .toc__link--assignment {
    padding: 0 10px 0 44px;
    min-height: 34px !important;
    line-height: 34px;
    color: inherit;
    font-size: 13px;
    @extend .d-flex;
    align-items: center;
  }
  .toc__completion + .toc__link--page,
  .toc__completion + .toc__link--assignment {
    padding: 0 10px 0 38px;
  }
  .toc__link--assignment {
    max-width: none;
  }
  .toc__completion ~ .toc__link--assignment {
    padding: 0 10px 0 56px;
  }
  .toc__assignment-icon {
    margin-right: 10px;
    margin-top: -3px;
  }
  .toc__completion + .toc__assignment-icon {
    left: 38px;
  }
  .toc__page-number {
    width: 45px;
    height: 34px;
    line-height: 34px;
    padding: 0 5px 0 0;
    text-align: center;
    font-size: 10px;
  }
  .toc__list-item--bottom {
    border-bottom: 1px solid $magic2;
    max-width: calc(100%);
    width: calc(100%);
  }
  .toc__link--bottom {
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    font-size: 14px;
    max-width: $menu-width;
    width: 100%;
  }
  .toc__link--bottom-icon {
    width: 18px;
    height: 14px;
    margin-right: 12px;
  }
  .assignment_label_data_wrapper {
    margin: 0;
  }
  .toc__assignment-label {
    margin: 0;
  }
  .toc__metadata {
    margin-top: 0;
  }
  .toc__list-item--not-available .toc__link {
    display: flex;
    flex-wrap: wrap;
  }
  .toc__label {
    margin-top: 0;
  }
}
.course-navigation__overlay {
  all: unset;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: none;
  &:focus {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75);
    outline: 0;
  }
}

.course-navigation__search--dirty {
  + .toc__list--unit {
    display: none;
  }
}
.course-navigation__search--input-wrapper {
  background-color: $magic1;
  padding: 10px 15px;
  position: relative;
  border-bottom: 1px solid $magic2;
}
.course-navigation__search--input {
  background-color: $white;
  border: 1px solid $magic2;
  box-shadow: none;
  font-size: 16px;
  color: $ninja11;
  padding-left: 37px;
  padding-right: 56px;
  &::placeholder {
    color: #596f85;
  }
  &:focus {
    border-color: #596f85;
    box-shadow: none;
    + .input-group-append .course-navigation__search--close-button {
      border-color: #596f85;
      border-left-color: $white;
    }
  }
}
.course-navigation__search--icon {
  position: absolute;
  top: 21px;
  left: 26px;
  z-index: 3;
}
.course-navigation__search--close-button {
  position: absolute;
  top: 11px;
  right: 16px;
  background: $white;
  box-shadow: none;
  border: 0;
  border-radius: 0 0.25rem 0.25rem 0;
  transition: none;
  width: 46px;
  height: 36px;
  &:hover,
  &:focus,
  &:active {
    background: $white !important;
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: 0 0 0 3px $ocean5 !important;
  }
}
.course-navigation__search--results-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(100vh - 119px);
  width: 100%;
  @include custom-scrollbar(rgba(210, 217, 237, 0.5), transparent);
  &:hover {
    overflow-y: auto;
  }
  &:empty {
    display: none;
  }
}
.course-navigation__search--results-item {
  border-bottom: 1px solid $magic2;
}
.course-navigation__search--results-item--highlighted {
  .course-navigation__search--results-link {
    box-shadow: inset 0 0 0 2px $ninja10;
    outline: 0;
  }
}
.course-navigation__search--results-link {
  font-size: 16px;
  color: $ninja11;
  padding: 12px 15px;
  display: block;
  line-height: 22px;
  &:focus,
  &:hover,
  &:active {
    color: $ninja11;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px $ninja10;
    outline: 0;
  }
}
.course-navigation__search--no-results-message {
  font-size: 16px;
  color: $ninja11;
  line-height: 22px;
  padding: 12px 15px;
  span {
    font-size: 14px;
    color: #596f85;
  }
}
