@mixin smallCaps()
    font-variant: small-caps

@mixin normalCaps()
    font-variant: normal

.aq-small-caps
    .page-location-bar,
    .course-navigation .toc,
    .workbook-page-reference-container,
    .course-mast,
    .course-dashboard,
    .lo-accordion *
        @include smallCaps()
        
    
    