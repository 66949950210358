.mast__waves
  display: flex
  flex-direction: column
  justify-content: flex-end
  width: 100%
  overflow: hidden
  transition: background-color 3ms

@media(min-width: 1800px)
  .mast__waves
    display: none

.mast__waves-svg
  width: 105%
  height: auto
  margin-bottom: -1px
  margin-left: -3px
  @include media-breakpoint-down(sm)
    width: 900px
    padding-top: 10px
