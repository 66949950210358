.error-page
  padding: 80px 20px 20px

.error-page__wrapper
  max-width: 500px
  margin: 0 auto
  border: 1px solid $magic2
  padding: 50px 20px 40px
  text-align: center
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.10)
  border-radius: 5px

.error-page__heading
  font-size: 28px
  font-weight: normal
  color: $ninja10
  margin: 32px 0 0 0

.error-page__subheading
  padding-top: 10px
  font-size: 18px
  color: $ninja10
  margin: 0