.course-mast
  margin-bottom: 50px
  overflow: hidden
  z-index: 1
  width: 100%
  padding-top: 30px
  transition: background-color 3ms

.course-mast__wrapper
  text-align: left

.course-mast__subtitle
  font-weight: 400
  font-size: 16px
  color: $ocean1
  letter-spacing: 0
  text-align: left
  margin-top: 31px
  margin-bottom: 8px
  line-height: 19px

.course-mast__title
  color: $white
  font-weight: 400
  font-size: 2.25rem
  line-height: 2.75rem
  color: #fff
  letter-spacing: 0
  margin-bottom: 0
  @include media-breakpoint-down(sm)
    font-size: 1.75rem
    line-height: 2.125rem

@media(min-width: 1800px)
  .course-mast
    padding-bottom: 30px

.gradient
  background: linear-gradient(to right,  rgba(255,255,255,.75) 0%,rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%)

.course-mast__title__placeholder
  @extend .gradient
  padding: 0px
  background-size: 200%
  animation: animate-gradient 4s ease infinite
  line-height: 44px
  height: 44px
  width: 50%

@keyframes animate-gradient
  0%
    background-position: 40% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 40% 50%
