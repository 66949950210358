%lx-shadow
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15)

.books
  margin: 0
  padding: 0
  .book 
    @extend %lx-shadow
    margin-bottom: 20px
    background: white
    overflow: hidden
    list-style: none

    &--cover
      width: 250px
      height: 280px
      margin-right: 30px
      float: left
      background-repeat: no-repeat
      background-size: 100%
      text-align: center
      img
        height: 100%
        max-width: 100%
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.25)
        margin: 0 auto
    &--title
      margin-top: 20px
      margin-bottom: 15px
      color: $cloud9
    &--authors, &--vbid
      font-size: 16px 
      color: #596F85
      margin-bottom: 5px
    &--vbid
      margin-bottom: 30px
    &--link
      width: 170px
      text-align: center

    @include media-breakpoint-down(sm)
      padding: 20px
      &--cover
        display: none
      &--title
        margin-top: 0
        font-size: 22px
