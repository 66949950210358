$content-width: 900px

$collapse-sm: 600px
$z-dropdown: 250
$border-radius: .25rem

$main-bg-color: #fff

$footer-height-sm: 128px
$footer-height-md: 90px
$footer-with-navigation-margin-top: 100px
$footer-text-color: #596F85
$footer-separator-color: #B2BEE0

$navbar-height: 60px
$navbar-logo-max-width: 250px
$navbar-logo-max-height: 44px
$navbar-sm-logo-max-width: 234px
$navbar-sm-logo-max-height: 44px
$navbar-xs-logo-max-width: 170px
$navbar-xs-logo-max-height: 30px

// ----------------------------
// Bootstrap variable overrides
// ----------------------------
// only add variables here if they are different from
// the Bootstrap default variable values
$gray-100: $frost1
$gray-200: $frost2
$gray-300: $frost3
$gray-400: $frost4
$gray-500: $frost5
$gray-600: $frost6
$gray-700: $frost7
$gray-800: $frost8
$gray-900: $frost9

$blue:    $ocean6
$indigo:  $magic6
$purple:  $eggplant6
$pink:    $sangria6
$red:     $red8
$orange:  $cheddar6
$yellow:  $cheddar6
$green:   $pickle6
$teal:    $jungle6
$cyan:    $mist6

$border-color: $frost3
$enable-shadows: true
$body-color: $ninja11

$box-shadow-sm:       0 .125rem .25rem rgba($black, .1)
$box-shadow:          0 .125rem .25rem rgba($black, .2)
$box-shadow-lg:       0 1rem 3rem rgba($black, .175)

$navbar-nav-link-padding-x:         1rem
$nav-link-padding-y:                .5rem
$navbar-padding-y:                  0
$navbar-light-color:                $body-color
// $navbar-light-hover-color:          $body-color
$navbar-light-disabled-color:       $frost6


$dropdown-border-color:             none
$dropdown-link-hover-bg:            $frost2

$dropdown-divider-bg:       rgba($border-color, .5)
$dropdown-box-shadow:       $box-shadow
$dropdown-item-padding-y:   0.5rem

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
$headings-font-family: $font-family-sans-serif


// Forms

$input-color: $magic10
$input-border-color: $magic3
$input-box-shadow: none
$input-placeholder-color: #8093A6
$input-disabled-bg: $magic1