.page-location-bar
  background: $magic1
  border-bottom: 1px solid $magic2
  padding: 10px 15px
  .breadcrumbs
    margin-bottom: 10px

  @include media-breakpoint-down(md)
    .breadcrumbs-list
      display: inline-block
      .breadcrumb-item
        display: inline-block
        float: left
        &:last-child
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          width: 270px

      .breadcrumb-divider
        float: left
        top: -1px
        display: inline-block
        position: relative

  @include media-breakpoint-up(md)
    padding: 10px 30px 15px

  &_progress
    width: 260px
    height: 8px
    background-color: $magic2
    box-shadow: none
    border: 1px solid $magic5
    .progress-bar
      height: 8px

  .page-location-button-right
    display: inline-block
    line-height: 45px
    svg
      margin-top: -2px
