.assignment-type-selector
  display: flex
  flex-direction: column
  padding-right: 20px

  .title
    line-height: 24px
    font-family: 'Roboto'
    font-weight: 500
    font-size: 16px
    color: #1C1C1C
    padding: 0
    margin-bottom: 16px

  .type-selectors
    display: flex
    flex-wrap: wrap

  .selector
    position: relative
    box-sizing: border-box
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    align-items: center
    font-family: 'Roboto'
    white-space: nowrap
    border-radius: 16px
    vertical-align: middle
    justify-content: center
    text-decoration: none
    padding: 4px 12px 3px 12px
    margin-right: 8px
    margin-bottom: 24px

    input
      position: absolute
      pointer-events: none
      opacity: 0

    label
      display: inline-flex
      line-height: 21px
      font-family: 'Roboto'
      font-weight: 400
      font-size: 14px
      color: #1C1C1C
      padding: 0
      margin: 0
      pointer-events: none
      transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms

  .clear-selected-types
    line-height: 24px
    font-family: 'Roboto'
    font-weight: 500
    font-size: 16px
    text-decoration: underline
    background: none
    border: none
    margin: 0 0 24px 8px
    padding: 0 4px
