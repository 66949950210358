// Cheddar
$cheddar1         : #FEF3E9
$cheddar2         : #FEC491
$cheddar3         : #FEAD64
$cheddar4         : #F58525
$cheddar5         : #E06107
$cheddar6         : #CA500F
$cheddar7         : #B0430C
$cheddar8         : #983600
$cheddar9         : #772700
$cheddar10        : #571d00

// Ocean
$ocean1           : #EAF7FC
$ocean2           : #B0E3F4
$ocean3           : #75CFEC
$ocean4           : #00A7DB
$ocean5           : #0093C4
$ocean6           : #007FAD
$ocean7           : #006C94
$ocean8           : #005A75
$ocean9           : #00465C
$ocean10          : #003342

// Magic
$magic1           : #F2F4F9
$magic2           : #D2D9ED
$magic3           : #B2BEE0
$magic4           : #91A3D4
$magic5           : #7188C7
$magic6           : #5773BF
$magic7           : #425BA1
$magic8           : #334A88
$magic9           : #24386E
$magic10          : #002244

// Sangria
$sangria1         : #FDF2F5
$sangria2         : #FBCADA
$sangria3         : #F8A2BF
$sangria4         : #F57AA3
$sangria5         : #ED3E79
$sangria6         : #D81E5D
$sangria7         : #BF1B52
$sangria8         : #A61747
$sangria9         : #8C143C
$sangria10        : #610E29

// Jungle
$jungle1          : #E8F7F2
$jungle2          : #C0EADB
$jungle3          : #86E0C2
$jungle4          : #24C991
$jungle5          : #1A9C6E
$jungle6          : #16825C
$jungle7          : #12694A
$jungle8          : #0D4F38
$jungle9          : #0A3C2B
$jungle10         : #07291D

// Pickle
$pickle1          : #F4F6EA
$pickle2          : #E0EAB8
$pickle3          : #CCDE87
$pickle4          : #A3C523
$pickle5          : #7E9E06
$pickle6          : #637D05
$pickle7          : #536904
$pickle8          : #415203
$pickle9          : #303D02
$pickle10         : #202902

// Brick
$brick1           : #FEF3F1
$brick2           : #FAC3BB
$brick3           : #FAA094
$brick4           : #FA7D6D
$brick5           : #EB4F3B
$brick6           : #D24634
$brick7           : #B83E2E
$brick8           : #9E3528
$brick9           : #7A291F
$brick10          : #571D16

// Mist
$mist1            : #EBF9FC
$mist2            : #C9E5EA
$mist3            : #ACD5DD
$mist4            : #6EADBA
$mist5            : #5F96A1
$mist6            : #4F7E87
$mist7            : #41666E
$mist8            : #324E54
$mist9            : #273E42
$mist10           : #1D2D30

// Eggplant
$eggplant1        : #F8F5FA
$eggplant2        : #DDCBE9
$eggplant3        : #C4A4D9
$eggplant4        : #B389D0
$eggplant5        : #A775C9
$eggplant6        : #945EB9
$eggplant7        : #794D96
$eggplant8        : #5E3C75
$eggplant9        : #3F284F
$eggplant10       : #2D1C38

// Cloud
$cloud1           : #fafafa
$cloud2           : #f6f6f6
$cloud3           : #ececec
$cloud4           : #dadada
$cloud5           : #949494
$cloud6           : #757575
$cloud7           : #646464
$cloud8           : #525252
$cloud9           : #3c3c3c
$cloud10          : #1c1c1c

// Frost
$frost1           : #F6F8FC
$frost2           : #EDF0F5
$frost3           : #DFE4EB
$frost4           : #CED2D9
$frost5           : #888C94
$frost6           : #71747A
$frost7           : #5B5E63
$frost8           : #46494D
$frost9           : #343538
$frost10          : #1C1D1F


// Ninja
$ninja1           : #F1F1FA
$ninja2           : #E3E3F0
$ninja3           : #D5D5E2
$ninja4           : #CACAD9
$ninja5           : #BBBBCC
$ninja6           : #9E9EAD
$ninja7           : #7C7C89
$ninja8           : #595963
$ninja9           : #40404c
$ninja10          : #2f2f39
$ninja11          : #1b1b26

// Red
$red1             : #FFF2F4
$red2             : #FFE1E6
$red3             : #FABCC5
$red4             : #F78394
$red5             : #ED596F
$red6             : #E71D3A
$red7             : #D21C35
$red8             : #A9172C
$red9             : #7E1021
$red10            : #5C0C18

// Green
$green1           : #EDF7ED
$green2           : #D1EAD1
$green3           : #A3D4A3
$green4           : #79C679
$green5           : #35A335
$green6           : #048A04
$green7           : #047103
$green8           : #015E02
$green9           : #024D03
$green10          : #023302

// Basic
$white            : #FFFFFF
$black            : #000000
