@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/Roboto-Light.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Roboto-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Roboto-Light.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/Roboto-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Roboto-Light.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Roboto-Regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Roboto-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Roboto-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/Roboto-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Roboto-Regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Roboto-Medium.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Roboto-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Roboto-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/Roboto-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Roboto-Medium.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Roboto-Bold.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Roboto-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Roboto-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/Roboto-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Roboto-Bold.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/Roboto-Black.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
    url('../../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/Roboto-Black.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/Roboto-Black.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/Roboto-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/Roboto-Black.svg#Roboto') format('svg'); /* Legacy iOS */
}
