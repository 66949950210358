.form
  padding: 15px 20px 28px 20px
  border-radius: 5px
  background: white
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25)
  color: $magic10
  &__title
    @extend .h1
    @extend .h1--bordered
    &--small
      @extend .h1--small
      @extend .h1--small--bordered

  &__subtitle
    font-size: 18px
    line-height: 22px
    font-weight: 600
    margin-bottom: 12px
  &__label
    font-size: 16px
    line-height: 19px
    font-weight: 600
    margin-bottom: 9px
  &__links
    display: flex
    list-style: none
    align-items: center
    justify-content: center
    line-height: 16px
    color: #596F85
    padding: 20px 0 14px
    margin: 0
    li
      display: inline-block
      line-height: 16px
      font-size: 14px
      a
        color: $ocean6
      + li
        margin-left: 30px
        position: relative
        &:before
          content: ""
          display: block
          width: 1px
          height: 100%
          background-color: $magic3
          position: absolute
          left: -14px
          top: 1px
  &__link
    color: #596F85
    font-size: 14px
    line-height: 16px
    text-decoration: underline
    &:hover
      text-decoration: underline
    &--bold
      line-height: 22px
      font-weight: bold
      font-weight: 500
      text-decoration: underline
      &:hover
        text-decoration: underline

  &__form-group--submit-success
    text-align: center
    margin: 0
    &__message
      font-size: 20px
      line-height: 26px
      margin: 15px 0 0 0
      &-link
        text-decoration: underline
        color: $ocean7
        &:hover
          text-decoration: underline
  &__fieldset
    background-color: inherit
    &:disabled
      a, #captcha-wrapper
        pointer-events: none
  &__instructions
    color: $magic10
    font-size: 16px
    line-height: 24px
    padding-left: 18px
    li + li
      margin-top: 8px
  &__separator
    text-align: center
    border-bottom: 1px solid #B2BFE0
    margin: 24px 32px 40px
    background-color: inherit
    line-height: 24px
    span
      padding: 0 15px
      background-color: inherit
      position: relative
      top: 12px
      font-size: 16px
      line-height: 24px
  .form-group
    margin-bottom: 18px
    div[class*='col-']
      @include media-breakpoint-down(xs)
        margin-bottom: 18px
        &:last-child
          margin-bottom: 0
  .form-text
    font-size: 13px
    line-height: 15px
    color: $magic10 !important
    margin-top: 8px
  .alert
    padding: 15px
    line-height: 21px
    &-primary
      color: $ocean7
      background-color: $ocean1
      border-color: $ocean6
    li + li
      margin-top: 5px
    a
      text-decoration: underline
      font-weight: 500
      font-size: inherit
      color: $ocean7
      &:hover
        text-decoration: underline
    &.no-bottom-margin
      margin-bottom: 0

  .invalid-feedback
    font-weight: 500
    font-size: 14px
    margin: 8px 0 0 0
  select.is-invalid
    background-position: right calc(0.375em + 0.75rem) center
  option
    padding-left: 0
    padding-right: 0
  p
    margin-bottom: 24px
    a
      text-decoration: underline
      color: $ocean6
      font-weight: 500
      &:hover
        text-decoration: underline
  dl
    margin-bottom: 32px
    dt
      margin-bottom: 4px
    dd + dt
      margin-top: 16px