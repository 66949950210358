.courses-link
  color: #2F2F39
  &:hover
    color: #2F2F39

.courses-mast
  margin-bottom: 50px
  overflow: hidden
  z-index: 1
  width: 100%

@media(min-width: 1800px)
  .courses-mast__waves
    display: none

.courses-mast__title
  color: $white
  font-weight: 400
  font-size: 2.25rem
  line-height: 2.75rem
  color: #fff
  letter-spacing: 0
  margin: 30px 0 30px
  @include media-breakpoint-down(sm)
    font-size: 1.75rem
    line-height: 2.125rem

.courses-not-enrolled__title
  margin-bottom: 36px

.courses-card
  min-height: 225px
  box-shadow: 0 -3px 0 0 rgba(0,0,0,.5), 0 1px 3px 0 rgba(0,0,0,0.15)
  border-radius: 4px
  background: #EFEFEF
  &:not(.pending-course)
    background: $white
    &:hover
      cursor: pointer
      box-shadow: 0 0 0 3px #0093C4
  .card-title 
    flex: 0 0 auto
    h5
      font-size: 22px
      color: #2F2F39
      letter-spacing: 0
      text-align: left
      line-height: 32px
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      margin-bottom: 0
      .badge
        font-size: 10px
        font-weight: normal
        margin-left: 10px
        padding: 3px 5px
  &.pending-course
    .card-title h5
      align-items: center
  &__info
    flex: 1 1 auto
    list-style: none
    margin: 0
    padding: 0
    li
      font-size: 12px
      color: #596F85
      line-height: 18px
      margin-bottom: 5px
      display: flex
      align-items: top
  &__icon-wrapper
    width: 18px
    display: inline-block
    margin-right: 6px
    &__calendar, &__instructor
      img
        top: -2px
        position: relative
  &__metadata
    word-break: break-all
    -webkit-user-select: all
    -moz-user-select: all
    -ms-user-select: all
    user-select: all
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    cursor: text
  .card-body
    display: flex
    flex-direction: column

.course-info_button
  justify-self: flex-end
  flex: 0 0 auto
  align-self: flex-end

.help-text
  font-size: 12px
  color: #596F85


.add-course-card
  text-align: center
  .card-icon
    margin: 10px auto
    min-height: 43px
  h4
    font-size: 22px
    margin-bottom: 14px
  p
    font-size: 16px
    color: $ocean10
    letter-spacing: 0
    margin-bottom: 0

.add-course-card.course-key-card
  text-align: left
  p
    font-size: 18px
  label
    font-weight: bold
    text-align: left
    display: block
  .alert
    text-align: left

.course_info
  display: flex
  justify-content: center
  margin-bottom: 20px

  li
    display: inline
    margin-right: 15px

.payment
  &--alert
    @extend .primary-alert
    margin-top: -1rem
    margin-bottom: 1rem
  .heading
    font-weight: 400
    font-size: 32px
    text-align: center
    margin-bottom: 40px

  &--column
    display: flex
    margin-bottom: 30px
    .card
      width: 100%
      height: 100%
      margin-bottom: 0
      .card-body
        display: flex
        flex-direction: column
        background-color: #fff
        padding: 1em
        width: 100%
        p
          flex: 1 0 auto

  .card
    @extend .add-course-card
    text-align: center
    h4
      margin-bottom: 0
    label, .form-group, .alert
      text-align: left
    p.card-paragraph
      font-size: 16px
      text-align: left
    &--trial
      min-height: 0
      .card-body
        padding: 15px
      p
        font-size: 22px
        color: #596F85
        margin-bottom: 0
        button
          margin-left: 30px
          position: relative
          top: -1px

.clipboard-alert
  margin-left: 20px