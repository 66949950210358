// Donut
// Donut Chart Mixin
.donut-chart
    position: relative
    border-radius: 50%
    overflow: hidden

    $size: 50px
    $width: 8px

    .slice
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

    .chart-center
        position: absolute
        border-radius: 50%

        top: $width
        left: $width
        width: $size - ($width * 2)
        height: $size - ($width * 2)

        span
            display: block
            text-align: center

            font-size: 13px
            line-height: $size - ($width * 2)
            color: $cloud9
            font-weight: 900

.donut-chart__completed
    .slice
        &.one
            background: #7DDA7D !important
        &.two
            background: #7DDA7D !important
    .chart-center
        background: #52A63A !important
    img
        position: relative
        top: -1px

.donut-chart__small
    $size: 30px
    $width: 6px
    .chart-center
        position: absolute
        width: $size - ($width * 2)
        height: $size - ($width * 2)
        top: $width
        left: $width