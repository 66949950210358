.email-lookup
  .search
    position: relative
    display: flex
    flex-direction: row
    align-items: flex-end
    padding-bottom: 30px

  .list
    position: absolute
    width: calc(100% - 50px)
    z-index: 5
    background-color: #FFFFFF
    border-radius: 4px
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5)
    transform: translateY(-25px)

    &-item
      display: flex
      flex-direction: column
      padding: 8px 16px
      cursor: pointer
      background-color: transparent
      border: none
      width: 100%
      // border-bottom: 1px solid #DADADA

      .name
        color: #1C1C1C
        font-size: 16px
        line-height: 24px
        text-transform: capitalize
      .email
        color: #757575
        font-size: 14px
        line-height: 21px