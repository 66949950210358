.cookie-policy-banner
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background:  $ninja11
  color: #ffffff
  padding: 30px
  line-height: 35px
  font-size: 16px
  z-index: 999
  text-align: left
  transform: translate3d(0, 100%, 0)
  transition-property: transform
  transition-duration: 0.3s
  transition-timing-function: ease-out
  transition-delay: 0s
  &.show
    transform: translate3d(0, 0, 0)
    transition-delay: 2s
  @media (min-width: 992px)
    text-align: center
  p
    margin-bottom: 0
  a
    color: #00A9DF
  button
    margin-top: 24px
    width: 100%
    vertical-align: baseline
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px)
      margin-top: 0px
      margin-left: 15px
      width: auto
    &:focus
      box-shadow: 0 0 3px rgba(255,255,250,.7) !important
      border: 1px solid white
