$menu-width: 360px
$toggler-width: 47px
$unitHeight: 66px

.menu-opened
  overflow: hidden
  .page-navigation-wrapper
    visibility: visible

  .page-navigation
    transform: translate3d(0, 0, 0)

  .page-navigation__overlay
    display: block

.page-navigation-wrapper
  visibility: hidden
  transition: visibility 0.2s


.page-navigation
  @extend .d-flex
  @extend .flex-column
  @extend .align-items-stretch
  @include custom-scrollbar(rgba(210, 217, 237, 0.5), transparent)
  padding: 0
  background: $white
  width: 100%
  z-index: 3
  position: fixed
  top: 0
  bottom: 0
  left: 0
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.2)
  transition: transform 0.2s ease-out
  transform: translate3d(-100%, 0, 0)
  @media (min-width: 360px)
    width: $menu-width
    min-width: $menu-width


.page-navigation__overlay
  all: unset
  background: rgba(0, 0, 0, 0.5)
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  width: 100vw
  height: 100vh
  z-index: 2
  display: none
  &:focus
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.75)
    outline: 0



