.lx-footer
  background-color: $main-bg-color
  font-weight: 400
  font-size: 14px
  color: $footer-text-color
  min-height: $footer-height-sm
  padding-top: 50px
  padding-bottom: 24px
  @include media-breakpoint-up(md)
    min-height: $footer-height-md
  a
    color: $footer-text-color
  &.with-navigation
    background-color: $magic1
    border-top: 1px solid $magic2
    padding-top: 30px
    margin-top: $footer-with-navigation-margin-top
.lx-footer__copy,
.lx-footer__misc
  padding: 9px 0
  text-align: center
  line-height: 16px
  @include media-breakpoint-up(md)
    padding: 0
    text-align: left

.lx-footer__misc
  a
    padding: 0 10px
    &:hover
      text-decoration: underline
      color: $ninja11
    &:last-child
      border-left: 1px solid $footer-separator-color

.lx-footer_navigation
  margin-bottom: 15px

.page-link.home
  height: 48px
  padding: 0
  display: inline-block
  border-radius: 4px
  margin-bottom: 1rem
  &:hover, &:active
    border-color: #8093A6
  &:focus
    border-color: #8093A6
    box-shadow: 0 0 0 1px #8093A6
    z-index: 1